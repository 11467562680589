import { createTheme } from '@mui/material';
import { deepPurple} from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary:  {
      main: deepPurple[500],
    },
    secondary: {
      main: deepPurple[50],
    },
  },
  typography: {
    fontFamily: 'inter',
    color: '#363636',
    subtitle1: {
      fontWeight: '500',
      color: '#363636',
    },
    h3: {
      color: '#363636',
    },
    h6: {
      color: '#363636',
    },
    body2: {
      color: '#6a6a6a',
    },
    body1: {
      color: '#363636',
    },
    title1: {
      fontWeight: '600',
      fontSize: '20px',
      
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '14px',
      color: '#363636',
    },
    caption: {
      color: '#b3b3b3',
    },
  },

  components: {

    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: '2rem', // Replace with your desired font size
          fontWeight: 700, // Replace with your desired font weight
          marginLeft: '18px'
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {

        root: {
          boxShadow: 'none',
          border: '1px solid #ccc',
          borderRadius:'20px',
          marginTop: '20px',

          '&.Mui-expanded': {
     
          },

          '&:before': {
            display: 'none',
          },
          '&:last-child': {
            borderRadius:'20px',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color:deepPurple,
           border:'none', 
          '&.Mui-expanded': {
            borderBottom:'1px solid #ccc',
            color:deepPurple,
          },
          '&:last-child': {
            borderRadius:'20px',
          },
        },
        expandIcon: {
          
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          
          '&:last-child': {
            borderRadius:'20px'
          },
        
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'none',
          position: 'static',
          fontSize: '0.8rem',
          color: '#363636',
          fontWeight: '600',
          marginBottom: '4px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            outline: '1px solid #ccc',
            border: '0',
            height: '32px',
            borderRadius: '100px',
            fontSize: '0.8rem',

            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
          '& .MuiInputBase-root:hover': {
            outlineColor: '#512da8',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
          padding: '4px 16px',
          fontSize: '0.8rem',
          fontWeight: '400',
          borderWidth: '0.1rem',
          boxShadow: 'none',
        },
      },
    },

    // MuiInputBase: {
    //   input: {
    //     background: '#ccc',
    //   },
    // },
  },
});

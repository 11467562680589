import { Box, Button, Container, Typography } from "@mui/material";
import styled from "@emotion/styled";
import eaarth2 from "./assets/eaarth2.png";
import bg from "./assets/bg.jpg";

function App() {
  const Section = styled("div")({
    position: "absolute",
    width: "100%",
    height: "100%",
    component: "img",
    margin: "0",
    padding: "0",
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  });

  return (
    <>
      <Section>
        <Container
          sx={{
            textAlign: "center",
            width: { sx: "90%", sm: "54%" },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src={eaarth2} height={80} alt="" />
          <Typography variant="h4" mt={4}>The Future of Film Production
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle2">
            Stay organized, collaborate effectively, and bring your creative vision to life. 
            Manage your movie projects seamlessly with our intuitive interface. 
            From pre-production to post, Earth Studios has got you covered.
            </Typography>
            <Box mt={6}>
            <a href="https://wa.me/447377005116" target="_blank">
              <Button
                variant="contained"
                size="small"
                sx={{ marginRight: "20px" }}
              >
                Contact us
              </Button>
              </a>
              <a href="https://wa.me/447377005116" target="_blank">
              <Button size="small" variant="outlined">
                Request Demo
              </Button>
              </a>
            </Box>
          </Box>
        </Container>
      </Section>
    </>
  );
}

export default App;
